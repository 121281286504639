import React from 'react';
import Head from '../components/head';
import homeStyles from './index.module.scss';

import Presentation from '../components/presentation/presentation'

const IndexPage = () => {

    return (
        <div class={homeStyles.content}>
            <Head title='Home' />
            <Presentation />

        </div>
    )
}

export default IndexPage