import { Link } from 'gatsby';
import React from 'react';
import Typist from 'react-typist'

import presentationStyles from './presentation.module.scss';

class Presentation extends React.Component {
    render() {
        const interlineDelay = 400;
        return (
            <Typist avgTypingDelay={25}>
                <div class={presentationStyles.presentation}>
                    <p className={presentationStyles.text}>
                        <Typist.Delay ms={interlineDelay}/>
                        <b>Hi!</b>
                        <br />
                        <Typist.Delay ms={interlineDelay}/>
                        I am Theo a PhD student in computational biology at the University of Dundee.
                        <br />
                        <Typist.Delay ms={interlineDelay}/>
                        I like programming, cooking and climbing (learn a bit more about me <Link class={presentationStyles.link} to='/about'>here</Link>).
                        <br />
                        {/* <Typist.Delay ms={interlineDelay}/>
                        I have recently started to write <Link class={presentationStyles.link} to='/blog'>a few blog posts</Link>. */}
                    </p>
                </div>
            </Typist>
        )
    }
}

export default Presentation